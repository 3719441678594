$pillars: (
    news: (
        cardBackground: $brightness-97,
        lines: $news-main,
        kicker: $news-main,
        headline: $brightness-7,
        featureHeadline: $news-dark,
        byline: $news-main,
        commentCount: $brightness-60,
        headlineIcon: $news-main,
        metaText: $brightness-60,
        cutoutBackground: $news-main,
        invertedKicker: $news-bright,
        liveColour: $news-pastel
    ),
    opinion: (
        cardBackground: $brightness-97,
        lines: $opinion-main,
        kicker: $opinion-main,
        headline: $brightness-7,
        featureHeadline: $opinion-dark,
        byline: $opinion-main,
        commentCount: $brightness-46,
        headlineIcon: $opinion-main,
        metaText: $brightness-60,
        cutoutBackground: $opinion-main,
        invertedKicker: $opinion-bright,
        liveColour: $opinion-pastel
    ),
    sport: (
        cardBackground: $brightness-97,
        lines: $sport-main,
        kicker: $sport-main,
        headline: $brightness-7,
        featureHeadline: $sport-dark,
        byline: $sport-main,
        commentCount: $brightness-60,
        headlineIcon: $sport-main,
        metaText: $brightness-60,
        cutoutBackground: $sport-dark,
        invertedKicker: $sport-bright,
        liveColour: $sport-pastel
    ),
    arts: (
        cardBackground: $brightness-97,
        lines: $culture-main,
        kicker: $culture-main,
        headline: $brightness-7,
        featureHeadline: $culture-dark,
        byline: $culture-main,
        commentCount: $brightness-60,
        headlineIcon: $culture-main,
        metaText: $brightness-60,
        cutoutBackground: $culture-main,
        invertedKicker: $culture-bright,
        liveColour: $culture-pastel
    ),
    lifestyle: (
        cardBackground: $brightness-97,
        lines: $lifestyle-main,
        kicker: $lifestyle-main,
        headline: $brightness-7,
        featureHeadline: $lifestyle-dark,
        byline: $lifestyle-main,
        commentCount: $brightness-60,
        headlineIcon: $lifestyle-main,
        metaText: $brightness-60,
        cutoutBackground: $lifestyle-dark,
        invertedKicker: $lifestyle-bright,
        liveColour: $lifestyle-pastel
    ),
    special-report: (
        cardBackground: $special-report-dark,
        lines: $highlight-main,
        kicker: $highlight-main,
        headline: #ffffff,
        featureHeadline: #ffffff,
        byline: $highlight-main,
        commentCount: $brightness-60,
        headlineIcon: $highlight-main,
        metaText: $brightness-60,
        cutoutBackground: $highlight-main,
        invertedKicker: $highlight-main,
        liveColour: #ffffff
    )
);

@mixin colours($palette) {
    $cardBackground: map-get($palette, cardBackground);
    $darkerCardBackground: darken($cardBackground, 3%);
    $headline: map-get($palette, headline);
    $lines: map-get($palette, lines);

    background-color: $cardBackground;

    &:hover {
        background-color: $darkerCardBackground;
    }

    &.fc-item--type-comment {
        .fc-item__avatar {
            background-color: darken(map-get($palette, cutoutBackground), 3%);
        }

        .fc-item__timestamp,
        .fc-trail__count--commentcount {
            background-color: $cardBackground;
        }

        .fc-item__container.u-faux-block-link--hover {
            background-color: $darkerCardBackground;

            .fc-item__timestamp,
            .fc-trail__count--commentcount {
                background-color: $darkerCardBackground;
            }
        }
    }

    //overides the most popular list item
    &.headline-list__item {
        background-color: #ffffff;
    }

    .fc-item__headline {
        color: $headline;
    }

    .fc-item__byline {
        color: map-get($palette, byline);
    }

    .fc-item__kicker {
        color: map-get($palette, kicker);
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title.fc-item--standard-mobile {
        .fc-item__content {
            background-color: map-get($palette, kicker);
        }

        &:hover {
            .fc-item__content {
                background-color: darken(map-get($palette, kicker), 5%);
            }
        }
    }

    //this is to make the colour lighter for the media cards
    &.fc-item--audio .fc-item__kicker,
    &.fc-item--gallery .fc-item__kicker,
    &.fc-item--video .fc-item__kicker,
    &.video-playlist__item .fc-item__kicker,
    &.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__byline
    {
        color: map-get($palette, invertedKicker);
    }


    &.fc-item--type-media .fc-item__headline .inline-garnett-quote {
        fill: map-get($palette, invertedKicker);
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__headline .inline-garnett-quote {
        fill: map-get($palette, liveColour);
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__byline {
        color: map-get($palette, liveColour);
    }

    &.fc-item--list-media-mobile.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__kicker,
    &.fc-item--list-mobile.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__kicker
    {
        color: map-get($palette, kicker);
        @include mq($from: tablet) {
            color: map-get($palette, invertedKicker);
        }
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title .fc-item__kicker
    {
        color: map-get($palette, kicker);
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title:not(.fc-item--pillar-special-report) .fc-item__standfirst {
        color: $brightness-7;
    }

    &.fc-item--type-immersive.fc-item--has-boosted-title .inline-icon
    {
        fill: $brightness-46;
        color: $brightness-46;
    }

    .fc-item__standfirst {
        color: $headline;
    }

    .fc-item__sublinks {
        color: $headline;
    }

    .inline-icon {
        fill: map-get($palette, headlineIcon);
    }

    .fc-item__meta {
        color: map-get($palette, metaText);
    }

    .fc-item__container {
        &:before {
            background-color: $lines;
        }
    }

    &.fc-item--type-feature .fc-item__headline {
        color: map-get($palette, featureHeadline);
    }

    &.video-playlist__item .video-overlay {
        border-top-color:  map-get($palette, invertedKicker);
    }

    &.video-playlist__item .inline-icon {
        fill: map-get($palette, invertedKicker);
    }

    &.video-playlist__item .video-overlay__duration {
        color: map-get($palette, invertedKicker);
    }

    &.fc-item--type-media,
    &.fc-item--type-interview {
        .inline-video-icon,
        .inline-volume-high,
        .inline-camera {
            svg {
                fill: $brightness-7;
            }

            &::after {
                background-color: map-get($palette, invertedKicker);
            }
        }
    }

    &.fc-item--type-media,
    &.fc-item--type-immersive {
        .fc-sublink__kicker {
            color: map-get($palette, invertedKicker);
        }
    }

    .fc-item__media-meta {
        color: map-get($palette, invertedKicker);

        .inline-icon {
            width: 24px;
            height: 23px;
            display: inline-block;
            background-color: map-get($palette, invertedKicker);
            border-radius: 50%;
            &.inline-video-icon svg {
                transform: translateY(1px);
            }
        }
    }

    .vjs-big-play-button .vjs-control-text {
        background-color: map-get($palette, kicker);
    }

    .youtube-media-atom__play-button {
        background-color: map-get($palette, kicker);
    }

    &.fc-item--type-comment:not(.fc-item--pillar-special-report):not(.fc-item--dynamic-layout) {

        background-color: $opinion-faded;

        .fc-item__container.u-faux-block-link--hover {
            background-color: darken($opinion-faded, 2%);
        }

        .fc-item__avatar {
            background-color: map-get($palette, cutoutBackground);
        }

        .fc-item__timestamp,
        .fc-trail__count--commentcount {
            color: map-get($palette, commentCount);
            background-color: $opinion-faded;

            .inline-icon {
                fill: map-get($palette, commentCount);
            }
        }

        &:hover {
            .fc-item__avatar {
                background-color: darken(map-get($palette, cutoutBackground), 3%);
            }

            .fc-item__timestamp,
            .fc-trail__count--commentcount {
                background-color: darken($opinion-faded, 2%);
            }
        }
    }
}

@each $pillar, $palette in $pillars {
    .fc-item--pillar-#{$pillar} {
        @include colours($palette);
    }
    .fc-sublink--pillar-#{$pillar} {
        .fc-sublink__kicker {
            color: map-get($palette, kicker);
        }
    }
}

.fc-item--paid-content {
    &,
    &.fc-item--gallery,
    &.fc-item--video,
    &.fc-item--audio,
    &.fc-item--pillar-news.video-playlist__item,
    &.fc-item--pillar-news.fc-item--type-immersive {
        .fc-item__kicker {
            color: $brightness-46;
        }
    }
}



.fc-item--pillar-special-report,
.fc-item--pillar-special-report.fc-item--type-feature {
    &.fc-item--type-immersive.fc-item--has-boosted-title.fc-item--standard-mobile {
        .fc-item__content {
            background-color: $special-report-dark;
        }

        &:hover {
            .fc-item__content {
                background-color: darken($special-report-dark, 5%);
            }
        }
    }

    &.fc-item--type-comment {

        .fc-item__byline {
            display: inline;
            color: $highlight-main;
        }

        .fc-item__kicker {
            color: $highlight-main;
        }
    }

    &.fc-item--type-media {
        background-color: darken($special-report-dark, 8%);

        .youtube-media-atom__play-button.vjs-control-text {
            .inline-play svg {
                fill: $brightness-7;
            }
        }
    }

    .fc-item__container.u-faux-block-link--hover {
        background-color: darken($special-report-dark, 2%);
    }

    .fc-sublink__kicker {
        color: $highlight-main;
    }

    .fc-sublink__title {
        color: #ffffff;
    }
}
