@mixin testimonial-styles {
    .epic__testimonial-container {
        margin: $gs-baseline 0;
        position: relative;
    }

    .epic__testimonial-quote {
        position: absolute;
        left: $gs-gutter / 2;
        top: 0;

        span.inline-garnett-quote {
            svg {
                width: $gs-gutter;
                height: $gs-gutter;
                fill: $brightness-60;
            }
        }
    }

    .epic__testimonial-text {
        @include fs-textSans(5);
        font-style: italic;

        margin: 0;
        padding: 0 (3 * $gs-gutter);
    }

    .epic__testimonial-name {
        @include fs-textSans(3);
        font-style: normal;
        display: block;
        margin-top: 5px;
    }
}

@include testimonial-styles;

.from-content-api {
    // needed to override nested selectors from _from-content-api.scss,
    // for instance .from-content-api cite
    @include testimonial-styles;
}
