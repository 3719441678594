/*

List item. Looks a bit like this:

***********************************************
x x x x x x x x x x x x x x x x x x x x x x x
x x x x x x x x x x x x x x x x x x x x x x x x

***********************************************

*/

@mixin fc-item--list {
    $item-vertical-padding: $gs-baseline * .25;

    .fc-item__header {
        @include fs-headline(2, true);
        @include fs-headline-quote(2);
        @include headline-boost(3);
    }

    &.fc-item--has-cutout {
        .fc-item__avatar {
            display: none;
        }
    }


    &:not(.fc-item--paid-content) {
        @include mq($until: tablet) {
            &:not(.fc-item--list-media-mobile) .fc-item__media-wrapper {
                display: none;
            }
        }
        @include mq(tablet) {
            &:not(.fc-item--list-media-tablet) .fc-item__media-wrapper {
                display: none;
            }
        }
    }
}
