$weather-small-size: 32px;
$weather-large-size: 50px;

[data-component='headlines'] {
    @include mq($until: leftCol) {
        &.fc-container--first {
            padding-top: 0;
        }

        .fc-container__inner {
            padding-top: 0;
        }

        .fc-container__header__title {
            padding-top: $gs-baseline /2;
        }
    }

    .has-page-skin & {
        @include mq(wide) {
            &.fc-container--first {
                padding-top: 0;
            }

            .fc-container__inner {
                padding-top: 0;
            }

            .fc-container__header__title {
                padding-top: $gs-baseline /2;
            }
        }
    }
}

.weather {
    @include fs-textSans(6);
    position: relative;
    letter-spacing: -.035rem;

    @include mq($until: tablet) {
        &.is-expanded {
            padding-top: $weather-small-size + $gs-baseline;
        }
    }

    @include mq(tablet) {
        display: flex;
        justify-content: flex-end;
    }

    @include mq(leftCol) {
        flex-direction: column;
        margin-top: $gs-baseline * 2;
    }

    .has-page-skin & {
        @include mq(wide) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 0;
        }
    }
}

.weather__icon {
    display: block;

    @include mq($until: leftCol) {
        position: absolute;
        left: $gs-gutter / 4;
        top: $gs-baseline / 2 + $gs-baseline / 4;
    }

    @include mq(leftCol) {
        margin-bottom: $gs-baseline / 4;
    }

    .has-page-skin & {
        @include mq(wide) {
            position: absolute;
            left: $gs-gutter / 4;
            top: $gs-baseline / 2 + $gs-baseline / 4;
        }
    }

    &  > svg {
        display: block;
        width: $weather-small-size;
        height: $weather-small-size;
    }
}

.weather__time {
    font-size: 12px;
    color: $brightness-46;
}

.weather__temp {
    font-weight: 400;
}

.weather__toggle-forecast {
    position: absolute;
    padding-left: gs-span(1) + $gs-gutter;
    padding-bottom: $gs-baseline;
    right: 0;
    top: 0;

    @include mq(tablet) {
        display: none;
    }

    &:focus {
        outline: 0;
    }

    .weather__toggle-icon {
        display: inline-block;
        margin-top: $gs-baseline + 2px;

        &:before {
            margin-right: 5px;
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border: 2px solid $brightness-7;
            border-top: 0;
            border-right: 0;
            transform: translateY(0) rotate(-45deg);
            transition: transform .3s;
        }

        .is-expanded &:before {
            transform: translateY(4px) rotate(135deg);
        }
    }
}

.weather__location {
    box-sizing: border-box;
    order: -1;
    position: relative;
    width: 100%;

    @include mq($until: tablet) {
        display: none;

        .is-expanded & {
            display: block;
            margin: ($gs-baseline / 2) 0 $gs-baseline;
        }
    }

    @include mq(tablet, leftCol) {
        margin: ($gs-baseline / 4) ($gs-gutter / 2) 0 0;
        width: gs-span(2);
    }

    @include mq(leftCol) {
        margin: 0 -2px ($gs-baseline / 4);
        width: gs-span(2) + 4px;
    }

    @include mq(wide) {
        width: gs-span(3) + 4px;
    }

    .has-page-skin & {
        @include mq(wide) {
            margin: ($gs-baseline / 4) ($gs-gutter / 2) 0 0;
            width: gs-span(2) + 4px;
        }
    }

    .search-tool__input {
        @include fs-textSans(5);
        border-radius: 16px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        font-weight: 400;
        width: 100%;
        display: block;
        padding: 0 35px 0 30px;
        box-sizing: border-box;
        transition: background-color .2s ease-in-out;

        &:focus {
            background-color: $brightness-97;
        }

        @include mq(tablet, leftCol) {
            background-color: $brightness-97;

            &:focus {
                background-color: $brightness-93;
            }
        }

        .has-page-skin & {
            @include mq(wide) {
                background-color: $brightness-97;

                &:focus {
                    background-color: $brightness-93;
                }
            }
        }
    }

    .is-editing .search-tool__input {
        background-color: $brightness-97;
    }

    .search-tool__btn {
        display: none;
    }

    .search-tool__list {
        .search-tool__link {
            padding-left: $gs-gutter +  $gs-gutter / 2;
        }

        @include mq($until: tablet) {
            position: static;
        }
    }
}

.weather__close-location,
.weather__edit-location {
    position: absolute;
    top: ($gs-baseline / 2) + 2px;
    display: block;
    z-index: 5;

    &:focus {
        outline: 0;
    }
}

.weather__close-location {
    right: 8px;
}

.weather__edit-location {
    left: 8px;
    pointer-events: none;
}

.weather__search-icon,
.weather__close-icon {
    fill: $brightness-46;

    svg {
        width: 16px;
        height: 16px;
    }
}

.weather__close-icon:hover {
    fill: $brightness-7;
}

.forecast {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq($until: tablet) {
        display: none;

        .is-expanded & {
            border-bottom: 1px solid $brightness-86;
            border-top: 1px solid $brightness-86;
            padding: 0 0 ($gs-baseline / 2);
            display: flex;
            align-content: flex-end;
            position: relative;
            width: 100%;
        }
    }

    @include mq(tablet, leftCol) {
        margin-right: -$gs-gutter / 2;
    }

    @include mq(leftCol) {
        border-top: 1px solid $brightness-86;
    }

    .has-page-skin & {
        @include mq(wide) {
            border-top: 0;
            margin-right: -$gs-gutter / 2;
        }
    }
}

.forecast__item {
    box-sizing: border-box;
    min-height: $weather-small-size + $gs-baseline;
    padding: 2px ($gs-gutter / 2) 0 ($weather-small-size + $gs-gutter / 2);
    position: relative;

    &:before {
        border-left: 1px solid $brightness-86;
        bottom: 10px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
    }

    @include mq($until: tablet) {
        flex: 1;
    }

    @include mq(leftCol) {
        flex: 1;
        padding-left: $gs-gutter / 4;

        &:before {
            bottom: $gs-baseline / 4;
        }
    }

    .has-page-skin & {
        @include mq(wide) {
            flex: none;
            padding-left: $weather-small-size + $gs-gutter / 2;

            &:before {
                bottom: 10px;
            }
        }
    }

    &.forecast__item--0 {
        @include mq($until: tablet) {
            &:before {
                content: none;
            }
        }

        @include mq(leftCol) {
            &:before {
                content: none;
            }
        }

        .has-page-skin & {
            @include mq(wide) {
                &:before {
                    content: '';
                }
            }
        }
    }

    &.forecast__item--2 {
        @include mq(tablet, desktop) {
            display: none;
        }
    }

    &.forecast__item--3 {
        @include mq($until: mobileMedium) {
            display: none;
        }

        @include mq(tablet, wide) {
            display: none;
        }

        .has-page-skin & {
            @include mq(wide) {
                display: none;
            }
        }
    }
}

.forecast__item--current {
    @include mq($until: tablet) {
        margin-right: $gs-gutter / 2;
        position: absolute;
        top: 0;
        right: 0;

        .weather__time,
        &:before {
            display: none;
        }

        .weather__temp {
            padding-top: $gs-baseline - 1px;
        }

        .weather__icon {
            top: ($gs-baseline / 4) - 1px;
        }
    }

    @include mq(leftCol) {
        border-top: 1px solid $brightness-86;
        position: relative;
        min-height: $weather-large-size + $gs-baseline * 2;
        padding-left: $weather-large-size + $gs-gutter / 2;

        &:before {
            content: none;
        }

        .weather__icon {
            position: absolute;
            top: $gs-baseline / 4;
            left: 0;

            svg {
                height: $weather-large-size;
                width: $weather-large-size;
            }
        }

        .weather__time {
            padding-top: $gs-baseline / 2;
        }

        .weather__temp {
            font-size: 22px;
        }
    }

    .has-page-skin & {
        @include mq(wide) {
            border-top: 0;
            min-height: auto;
            padding: 2px ($gs-gutter / 2) 0 ($weather-small-size + $gs-gutter / 2);

            &:before {
                content: '';
            }

            .weather__icon {
                left: $gs-gutter / 4;
                top: $gs-baseline / 2 + $gs-baseline / 4;

                svg {
                    height: $weather-small-size;
                    width: $weather-small-size;
                }
            }

            .weather__time {
                padding-top: 0;
            }

            .weather__temp {
                font-size: 18px;
            }
        }
    }
}
