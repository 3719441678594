*[class^='site-message--subscription-banner'] {
    box-sizing: border-box;
}

.site-message--subscription-banner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    background: $highlight-main;
    color: $brightness-7;
    width: 100%;
    padding: 10px;

    @include mq($from: tablet) {
        padding: 20px;
    }
}

.site-message--subscription-banner__inner {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 980px;

    @include mq($from: leftCol) {
        max-width: 1140px;
    }

    @include mq($from: wide) {
        max-width: 1300px;
    }
}

.site-message--subscription-banner__title {
    font-size: 22px;
    line-height: 22px;
    font-family: $f-serif-headline;
    font-weight: bold;
    width: 100%;

    @media (min-width: 350px) {
        font-size: 24px;
        line-height: 24px;
    }

    @media (min-width: 600px) {
        font-size: 28px;
        line-height: 28px;
    }

    @include mq($from: tablet) {
        margin-bottom: 10px;
        width: 60%;
        font-size: 30px;
        line-height: 32px;
    }

    @include mq($from: desktop) {
        width: 48%;
        margin-right: 52%;
        font-size: 34px;
        line-height: 36px;
    }
}

.site-message--subscription-banner__description {
    display: flex;
    align-items: flex-end;
    align-content: stretch;
    width: 95%;

    @include mq($from: tablet) {
        width: 55%;
    }

    @include mq($from: desktop) {
        width: 43%; // this an uneven number to remove an orphan word at the end of the copy
    }

    @include mq($from: wide) {
        width: 47%;
    }

    p {
        font-size: 17px;
        line-height: 18px;
        margin: 0;
        font-family: $f-serif-text;

        @include mq($from: mobileLandscape) {
            font-size: 18px;
            line-height: 20px;
        }

        // custom breakpoint
        @media (min-width: 550px) {
            font-size: 17px;
            line-height: 19px;
        }

        @include mq($from: phablet) {
            font-size: 17px;
            line-height: 19px;
        }

        @include mq($from: tablet) {
            font-size: 17px;
            line-height: 19px;
        }

        @include mq($from: desktop) {
            font-size: 20px;
            line-height: 22px;
        }

        @include mq($from: leftCol) {
            font-size: 20px;
            line-height: 26px;
        }
    }
}

.site-message--subscription-banner__cta-container {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin: 30px 0 0;

    @include mq($from: tablet) {
        display: flex;
        width: 60%;
    }

    @include mq($from: desktop) {
        width: 100%;
    }
}

.site-message--subscription-banner__cta {
    display: inline-block;
    font-size: 16px;
    width: 100%;
    padding: 10px 20px 12px;
    border-radius: 50px;
    background: $brightness-7;
    font-family: $f-sans-serif-text;
    color: $brightness-97;
    text-align: center;
    cursor: pointer;

    @include mq($from: tablet) {
        width: auto;
    }
}

.site-message--subscription-banner__cta:focus ,
.site-message--subscription-banner__cta:hover {
    text-decoration: none;
}

.site-message--subscription-banner__cta-dismiss-container {
    text-align: center;
    margin: 20px 0 8px;

    @include mq($from: tablet) {
        margin: 12px 0 0 10px;
    }

    &.site-message--subscription-banner__sign-in--already-signed-in {
        display: none;

        @include mq($from: tablet) {
            display: block;
        }
    }
}

.site-message--subscription-banner__cta-dismiss {
    display: none;
    color: $brightness-7;
    font-family: $f-sans-serif-text;
    font-size: 16px;
    width: 100%;
    cursor: pointer;

    @include mq($from: tablet) {
        display: block;
    }
}

.site-message--subscription-banner__sign-in {
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    font-family: $f-sans-serif-text;
    color: $brightness-7;
    margin-top: 10px;
    text-align: center;

    @include mq($from: tablet) {
        width: 60%;
        text-align: left;
    }

    p {
        display: inline-block;
        margin-right: 5px;
    }

    a {
        color: $brightness-7;
    }

    &.site-message--subscription-banner__sign-in--already-signed-in {
        display: none;

        @include mq($from: tablet) {
            visibility: hidden;
            display: block;
        }
    }
}

.site-message--subscription-banner__subscriber-link {
    text-decoration: underline;
    font-weight: bold;

    @include mq($from: tablet) {
        text-decoration: none;
        font-weight: normal;
    }
}

.site-message--subscription-banner__subscriber-link:hover,
.site-message--subscription-banner__subscriber-link:focus {
    text-decoration: none;
}

.site-message--subscription-banner__sign-in-link {
    text-decoration: underline;
    font-weight: bold;
}

.site-message--packshot-container {
    width: 100%;
    margin: 30px 0 -75px;

    @include mq($from: tablet) {
        margin-bottom: -105px;
    }


    @include mq($from: tablet) {
        position: absolute;
        width: 45%;
        right: 0;
        margin: 0;
        bottom: -35px;
    }


    @include mq($from: desktop) {
        width: 52%;
    }

    @include mq($from: wide) {
        bottom: -61px;
    }

    img {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: block;

        @include mq($from: mobileLandscape) {
            max-width: 380px;
        }

        @include mq($from: tablet) {
            margin-right: 0;
        }

        @include mq($from: desktop) {
            max-width: 420px;
            margin-right: 90px;
        }

        // custom breakpoint
        @include mq($from: leftCol) {
            max-width: 500px;
        }

        @media (min-width: 1260px) {
            margin-right: 120px;
        }
    }
}

.site-message--subscription-banner__lockup {
    display: flex;
    position: relative;
}

.temp-mobile-break {
    display: block;

    @media (min-width: 380px) {
        display: none;
    }
}

.site-message--subscription-banner__close-button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    svg {
        border-radius: 50%;
        padding: 2px;
        border: 1px solid $brightness-7;
        transition: background-color .5s ease;
    }

    svg:hover {
        cursor: pointer;
        background-color: rgba(237, 237, 237, .5); // $brightness-93
    }

    svg path {
        fill: $brightness-7;
    }
}

.site-message--subscription-banner__gu-logo {
    display: none;

    @include mq($from: desktop) {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 70px;
    }

    // custom breakpoint
    @media (min-width: 1260px) {
        width: 90px;
    }
}


/*********************** footer hacks *********************/



.subscription-banner--holder
.site-message__copy.js-site-message-copy.u-cf {
    margin: 0;
}

.subscription-banner--holder
.gs-container {
    margin: 0 10px;

    @include mq($from: tablet) {
        margin: 0 20px;
    }

    @include mq($from: desktop) {
        max-width: 980px;
        margin: 0 auto;
    }

    @include mq($from: leftCol) {
        max-width: 1140px;
    }

    @include mq($from: wide) {
        max-width: 1300px;
    }
}

/*********************** footer hacks END *********************/
