.site-message--engagement-banner {
    border-top: 1px solid $brightness-7;
    background-color: $highlight-main;
    max-height: 80%;

    // we have our own close button - see .engagement-banner__close-button
    .site-message__close {
        display: none;
    }

    .inline-marque-36 path:nth-child(2) {
        fill: $highlight-main;
    }

    @mixin padding {
        padding: $gs-baseline/1.5 0;

        @include mq(tablet) {
            padding: $gs-baseline/1.5 $gs-gutter $gs-baseline*1.5;
        }
    }

    .site-message__roundel {
        @include padding;

        display: none;
        position: absolute;

        svg {
            fill: $brightness-7;
        }

        // Because this roundel "jumps" from the right to left
        // at leftCol width, we display this one and hide the other
        // one at this point. See also: .engagement-banner__roundel
        @include mq(leftCol) {
            display: block;
        }
    }

    .site-message__close-btn {
        display: inline-block;
        border-color: $brightness-7;
    }

    .site-message__inner {
        display: block;
        width: auto;
    }

    .site-message__copy {
        @include padding;

        display: block;
    }
}

.engagement-banner__text {
    @include banner-copy-alignment;

    color: $brightness-7;
    font-size: 14px;
    line-height: 18px;

    @include mq(tablet) {
        @include fs-bodyCopy(2);
    }

    @include mq(desktop) {
        display: inline-block;
    }
}

.engagement-banner__highlight {
    font-weight: bold;
    background-color: #ffffff;
    padding: 1px;
}

.engagement-banner__container {
    position: relative;
}

.engagement-banner__cta {
    margin-top: $gs-baseline / 2;

    @include mq(tablet) {
        margin-top: $gs-baseline;
    }

    @include mq(desktop) {
        display: inline-block;
        vertical-align: bottom;
    }
}

.engagement-banner__button {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    height: 36px;
    padding: 0 ($gs-gutter / 3) 0 18px;
    margin-right: $gs-gutter / 4;
    margin-bottom: $gs-baseline / 2;

    font-size: 16px;
    line-height: 32px;

    background-color: $brightness-7;
    border-color: $brightness-7;

    .inline-icon {
        float: right;
        transition: transform ease-in-out .3s;

        svg {
            // weirdly needed for vertical centering
            padding-top: 2px;
        }

        .u-faux-block-link--hover & {
            transform: translateX(3px);
        }
    }

    @include mq(tablet) {
        margin-bottom: 8px;
    }

    &:focus {
        outline: auto;
        outline-color: $brightness-7;
    }
}

.engagement-banner__payment-logos {
    display: inline-block;
    vertical-align: middle;
    height: 17px;

    @include mq(tablet) {
        display: block;
        height: 18px;
    }

    svg, img {
        height: 100%;
        width: auto;
    }
}

.engagement-banner__close {
    float: right;

    // we want the close button above the link overlay
    // z-index only applies to positioned elements
    position: relative;
    z-index: 1;

    // to remove the space between inline-block children
    font-size: 0;
}

.engagement-banner__close-button,
.engagement-banner__roundel {
    display: inline-block;
    vertical-align: middle;

    height: 36px;
    width: 36px;
}

.engagement-banner__roundel {
    // Because this roundel "jumps" from the right to left
    // at leftCol width, we hide this one and display the other
    // one at this point. See also: .site-message__roundel
    @include mq(leftCol) {
        display: none;
    }
}

.engagement-banner__close-button {
    // for some reason, it's more centered with this 2px on top
    padding: 2px 0 0;
    margin: 0;

    background: transparent;
    border: 1px solid rgba($brightness-7,  .3);
    color: $brightness-7;
    transition: background-color .3s ease-in-out;

    svg {
        fill: $brightness-7;
    }

    &:hover,
    &:focus,
    &:active {
        background: rgba($brightness-7,  .05);
    }

    &:focus {
        outline: auto;
    }
}

.engagement-banner__header {
    font-weight: 700;

    @include mq($until: phablet) {
        @include fs-headline(2);
        margin: 0 -10px $gs-gutter / 4;
        padding: 0 10px $gs-gutter / 4;
        border-bottom: 1px solid rgba($brightness-7, .3);
        min-height: 40px;
    }

    @include mq(phablet) {
        display: inline;
    }
}
