.site-message--europe-moment {
    color: $brand-main;
    background-color: $brand-main;
    position: relative;
    overflow: hidden;
    padding-top: 0;

    .site-message__copy {
        padding: 0;
    }

    .site-message__roundel {
        display: none;
    }

    .engagement-banner__close {
        position: absolute;
        z-index: 1;
        right: 0;
        top: $gs-baseline;

        @include mq($from: tablet) {
            right: $gs-gutter;
        }

        @include mq($from: desktop) {
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .engagement-banner__roundel {
        display: none;

        @include mq($from: desktop) {
            display: block;
            position: relative;
            margin-right: $gs-gutter / 2;
        }

        path:first-of-type {
            fill: $brightness-97;
        }

        path:nth-of-type(2) {
            fill: $brand-main;
        }
    }

    .engagement-banner__close-button {
        border-color: $brightness-97;

        path {
            fill: $brightness-97;
        }
    }
}

.engagement-banner__container--europe-moment {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    @include mq($from: tablet) {
        flex-flow: row nowrap;
    }

    @include mq($from: desktop) {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 120%;
            width: 1px;
            background-color: $brightness-7;
            top: 0;
            z-index: 1;
        }
    }

    .engagement-banner__text-container {
        flex: 0 1 50%;
        position: relative;
        margin-bottom: $gs-baseline * 2;

        @include mq($from: tablet) {
            flex: 0 0 75%;
        }

        @include mq($from: leftCol) {
            flex: 0 1 50%;
            margin-bottom: $gs-baseline;
        }
    }

    .engagement-banner__image-container--mobile {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;

        @include mq($from: tablet) {
            display: none;
        }
    }

    .engagement-banner__image-container--tablet {
        display: none;

        @include mq($from: tablet, $until: leftCol) {
            display: block;
            flex: 0 1 25%;
            position: relative;
        }

        svg {
            position: absolute;
            right: $gs-gutter;
            bottom: $gs-baseline;
        }
    }

    .engagement-banner__image-container--desktop {
        display: none;

        @include mq($from: leftCol) {
            display: block;
            flex: 1 0 50%;

            svg {
                height: 100%;
            }
        }
    }

    .engagement-banner__header {
        padding: 0;
    }

    .engagement-banner__header-text  {
        display: none;
    }

    .engagement-banner__header-image {
        max-width: 100%;

        @include mq($from: tablet, $until: leftCol) {
            max-width: 400px;
        }
    }

    .engagement-banner__body {
        @include f-bodyCopy;
        color: $brightness-97;
        font-size: 15px;
        line-height: 1.35;

        @include mq($from: phablet) {
            font-size: 17px;
        }

        @include mq($from: tablet) {
            padding: $gs-baseline $gs-gutter 0 0;
            margin-left: $gs-gutter / 2;
        }
    }

    .engagement-banner__cta {
        position: relative;
        z-index: 1;
        padding-top: $gs-baseline / 2;
        display: block;

        @include mq($from: tablet) {
            display: inline-block;
            margin-left: $gs-gutter / 2;
        }
    }

    .engagement-banner__cta--editorial {
        color: $brightness-97;

        @include mq($from: tablet) {
            margin-left: $gs-gutter;
        }

        .component-button--greyHollow--europe-moment {
            border-color: $highlight-main;
            color: $highlight-main !important;

            &:hover, &:focus {
                background: transparent;
            }
        }
    }

}
