/*

Half item. Looks like a wide standard, a bit like this:

****************************************
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0
0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0

x x x x x x x x x x x x x x x x x x x x
x x x x x x x x x x x x x x x x x x x x x
x x x x x x x x x x x x x x x x x x







*****************************************

*/

@mixin fc-item--half {
    .fc-item__header {
        @include fs-headline(4, true);
        @include fs-headline-quote(4);
        @include headline-boost(5);

        @include mq(tablet, desktop) {
            @include fs-headline(3, true);
            @include fs-headline-quote(3);
            @include headline-boost(4);
        }
    }

    .fc-item__standfirst {
        display: block;
    }

    &.fc-item--has-cutout {
        @include avatar(5);

        @include mq(desktop) {
            @include avatar(6);
        }

        .fc-item__header {
            padding-bottom: gs-height(5);

            @include mq(desktop) {
                padding-bottom: gs-height(6);
            }
        }

        .fc-item__footer--vertical,
        .fc-item__standfirst-wrapper {
            padding-right: gs-height(4);

            @include mq(desktop) {
                padding-right: gs-height(4.5);
            }
        }

        // to show on slices where we're hiding standfirst for half cards but still need them on has-cutout
        .fc-item__standfirst {
            display: block;
        }

        &[class*='fc-item--has-sublinks']:not(.fc-item--has-sublinks-1) {
            .fc-item__header {
                padding-bottom: gs-height(2) + $gs-baseline * 2;
    
                @include mq(desktop) {
                    padding-bottom: gs-height(4.6);
                }
            }

            &:not(.fc-item--type-comment) {
                .fc-sublink {
                    margin-top: 0;
        
                    .fc-sublink__title {                
                        &:before {
                            width: auto;
                            left: 0 - $fc-item-gutter;
                            right: 0 - $fc-item-gutter;
                        }
                    }
                }
            }
        }
     }

    &[class*='fc-item--has-sublinks'] {
        &:not(.fc-item--has-cutout),
        &:not(.fc-item--has-sublinks-1) {
            @include fc-sublinks--below;
        }
    }
}
