// based on cellSize + borderSize from
// projects/common/modules/crosswords/constants.js
$xword-cell-width: 31px;
$xword-cell-width-small: 24px; // only used in anagram helper preview cells
$xword-cell-width-xsmall: 20px; // as above but for long (10+ letter) clues
$xword-border-width: 1px;

$xword-highlight-colour: $highlight-main;
$xword-focussed-colour: #000000;
$xword-focussed-background-colour: #fff7b2;

$xword-clue-number-width: 32px;

$xword-grid-sizes: (
    quick: 13,
    cryptic: 15,
    prize: 15,
    quiptic: 15,
    genius: 15,
    speedy: 13,
    everyman: 15,
    weekend: 13
);
