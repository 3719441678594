.l-list {
    width: 100%;

    @include mq(tablet) {
        .has-flex-wrap & {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.l-list__item {
    float: left;

    .has-flex-wrap & {
        flex-grow: 0;
        flex-basis: 100%;
    }
}

// We only need this functionality for 2 & 4 column rows.
@for $column from 2 through 4 {
    @for $span from 1 through 5 {
        .l-row--cols-#{$column} {
            .l-row__item--span-#{$span} {
                @include mq(tablet) {
                    width: (100% / $column) * $span;
                    float: left;

                    .has-flex & {
                        flex: $span 1 auto;
                    }
                }
            }
        }
    }
}

@for $column from 1 through 4 {
    .l-list--columns-#{$column} {
        .l-list__item {
            @include mq(tablet) {
                width: 100% / $column;

                &:nth-child(#{$column + 'n+1'}) {
                    clear: both;

                    &:before {
                        border: 0;
                    }
                }

                &:nth-last-child(#{$column}):nth-child(#{$column + 'n+1'}),
                &:nth-last-child(#{$column}):nth-child(#{$column + 'n+1'}) ~ .l-list__item {
                    padding-bottom: 0;
                }

                .has-flex-wrap & {
                    flex-basis: (100% / $column);
                }
            }
        }
    }
}
