.engagement-banner-ticker {
    display: block;
    position: relative;
    height: 70px;
    margin-top: 12px;
}

.engagement-banner-ticker__count {
    @include fs-headline(6);
    font-weight: 800;
    color: #333333;
}

.engagement-banner-ticker__count-label {
    @include fs-bodyCopy(2);
}

$progress-bar-height: 10px;

.engagement-banner-ticker__progress-container {
    width: 100%;
    height: $progress-bar-height;
    position: absolute;
    bottom: 0;
    margin-top: 40px;
}

.engagement-banner-ticker__progress {
    overflow: hidden;
    width: 100%;
    height: $progress-bar-height;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
}

.engagement-banner-ticker__filled-progress {
    background-color: #333333;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: transform 3s cubic-bezier(.25, .55, .2, .85);
}

.engagement-banner-ticker__goal-marker {
    border-right: 3px solid #121212;
    content: ' ';
    display: block;
    height: 17px;
    margin-top: -7px;
}

.engagement-banner-ticker__progress-labels {
    position: relative;
    width: 100%;
    height: 16px;
}

.engagement-banner-ticker__label {
    @include fs-textSans(4);
    color: #676767;
    position: absolute;
    top: 5px;
}

.engagement-banner-ticker__goal, .engagement-banner-ticker__exceeded {
    position: absolute;
    right: 0;
    bottom: $progress-bar-height + 5px;
    text-align: right;
}

.engagement-banner-ticker__so-far, .engagement-banner-ticker__thankyou {
    position: absolute;
    left: 0;
    bottom: $progress-bar-height + 5px;
}

.engagement-banner-ticker__thankyou {
    >:first-child {
        @include fs-header(5);
        font-weight: 800;
        color: #333333;
    }

    >:last-child {
        font-style: italic;
    }
}

.engagement-banner-ticker__exceeded .engagement-banner-ticker__count {
    @include fs-bodyCopy(2);
}

.engagement-banner-ticker__exceeded .engagement-banner-ticker__count-label {
    font-style: italic;
}

.engagement-banner-ticker__caption {
    @include fs-textSans(4);
    position: absolute;
    bottom: 6px;
    left: 10px;
    right: 10px;
    color: #ffffff;
    opacity: .7;
}
