/**
 * Brand badges
 */

.badge {
    @include fs-textSans(1);
    color: $brightness-46;
    font-weight: bold;
    margin-top: $gs-baseline / 2;
    padding-right: $gs-gutter / 2;
    padding-bottom: $gs-gutter / 2;
    text-align: right;

    .dumathoin-more + & {
        margin-top: 0;
    }

    .has-no-flex & {
        clear: both;
    }
}

.badge--alt {
    float: none;
    border-top: 0;
    margin-top: $gs-baseline / 3;
    padding-top: $gs-baseline / 6;
    text-align: left;
    padding-right: 0;

    @include mq(leftCol) {
        width: $left-column;
        float: left;
        clear: left;
        border-top: 1px dotted $brightness-86;
    }

    @include mq(wide) {
        width: $left-column-wide;
    }

    > .badge__link,
    .badge__logo {
        display: block;
    }

    .badge__logo {
        max-height: 6 * $gs-baseline;
        @include mq(tablet) {
            max-height: none;
        }
        max-width: 100%;
        margin: 0 0 5px;
    }

    > .badge__link {
        margin: 3 / 4 * $gs-baseline 0 $gs-baseline;
    }

    > .badge__help {
        @include font-size(11);
        color: $brightness-46;
        font-weight: normal;
    }
}

.fc-container {
    .badge--alt {
        @include mq(tablet) {
            float: left;
            clear: left;
            border-top: 1px dotted $brightness-86;
        }
    }
}

.content__main-column--article,
.content__main-column--liveblog,
.content--media--video,
.content--media--audio {
    .badge--alt {
        padding-bottom: 20px;
    }
}

.content__main-column--liveblog {
    .badge--alt {
        float: none;
    }
}

.content--media--video:not(.paid-content),
.content--media--audio:not(.paid-content),
.content--gallery:not(.paid-content) {
    .badge {
        color: $brightness-86;
    }

    .badge__help {
        color: #ffffff;
    }
}

// This solves a badging problem but in a very in-elegant way. Live blog templates need to be re-built using CCS grid
.content__head--is-badged {
    .content__headline {
        @include mq(leftCol) {
            min-height: 110px;
        }
    }
}

.badge--interactive {
    border-top: 1px dotted $brightness-46;

    @include mq(desktop) {
        min-height: 135px;
        position: absolute;
        top: $gs-baseline / 2;
        right: -1 * (gs-span(4) + $gs-gutter / 2);
    }

    @include mq(wide) {
        right: -1 * (gs-span(5) + $gs-gutter);
    }

    .badge__link {
        border-bottom-color: $brightness-46;
    }
}

.badge--branded {
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin: 0;
}

.badge__logo {
    max-height: $gs-baseline * 5;
    margin-left: $gs-gutter / 2;
    vertical-align: middle;
}
