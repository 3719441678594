@mixin garnett-underline($colour, $line-height, $factor: .25) {
    .fc-item__title {
        background-image: linear-gradient(to bottom, transparent, transparent calc(#{$line-height} - 1px), rgba($colour, .5));
        background-size: 1px $line-height;
        background-origin: content-box;
        background-clip: content-box;
        margin-right: -1 * $factor * $gs-gutter;
    }

}

$pillars: (
    news:      $news-main,
    opinion:   $opinion-main,
    sport:     $sport-main,
    arts:      $culture-main,
    lifestyle: $lifestyle-main,
    special-report: $highlight-main
);

.fc-item--type-analysis.fc-item--has-boosted-title {
    .fc-item__title {
        background: none !important;
        // Supporting this would double the CSS load of the page.
        // Individually disabling would also have an undue css load.

    }
}
@supports not (-ms-line-break: newspaper) {
    @each $pillar, $pillarColor in $pillars {

        .fc-slice-wrapper + .fc-slice-wrapper {
            .fc-slice--q-q-q-q {
                .fc-item--pillar-#{$pillar}.fc-item--type-analysis {
                    &.fc-item--standard-tablet {
                        @include mq(tablet) {
                            @include garnett-underline($pillarColor, 20px);
                        }
                    }
                }
            }
        }

        .fc-slice--t-t-mpu {
            .fc-item--pillar-#{$pillar}.fc-item--type-analysis {
                &.fc-item--third-tablet {
                    @include mq(tablet) {
                        @include garnett-underline($pillarColor, 20px);
                    }
                }
            }
        }
        .fc-item--pillar-#{$pillar}.fc-item--type-analysis {

            @include garnett-underline($pillarColor, 20px);

            @include mq($until: tablet) {
                &.fc-item--standard-mobile {
                    @include garnett-underline($pillarColor, 24px);
                }
            }

            @include mq(desktop) {
                &.fc-item--standard-tablet {
                    @include garnett-underline($pillarColor, 24px);
                }
            }

            @include mq(tablet) {
                &.fc-item--third-tablet,
                &.fc-item--full-media-50-tablet,
                &.fc-item--full-media-75-tablet, &.fc-item--half-tablet,
                &.fc-item--three-quarters-right-tablet,
                &.fc-item--three-quarters-tall-tablet,
                &.fc-item--three-quarters-tablet {
                    @include garnett-underline($pillarColor, 24px);
                }
                &.fc-item--full-media-100-tablet {
                    @include garnett-underline($pillarColor, 28px, .5);
                }

                @include mq(desktop) {
                    &.fc-item--half-tablet,
                    &.fc-item--three-quarters-right-tablet,
                    &.fc-item--three-quarters-tall-tablet,
                    &.fc-item--three-quarters-tablet {
                        @include garnett-underline($pillarColor, 28px, .5);
                    }
                    &.fc-item--full-media-50-tablet,
                    &.fc-item--full-media-75-tablet {
                        @include garnett-underline($pillarColor, 32px, .5);
                    }
                    &.fc-item--full-media-100-tablet {
                        @include garnett-underline($pillarColor, 36px, .5);
                    }
                }

            }

        }
    }
}
