.vergadain {
    box-sizing: border-box;
    display: block;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: .75rem;
    line-height: 1rem;
    padding-bottom: $gs-baseline / 2;

    &:hover,
    &:focus {
        text-decoration: none;

        .advert__title {
            text-decoration: underline;
        }
    }

    &,
    &:active {
        color: inherit;
    }

    .has-no-flex &:not(.vergadain--small):not(.vergadain--landscape) {
        padding-top: 11 * $gs-baseline;
        position: relative;
    }
}

.vergadain--landscape {
    .has-no-flex & {
        overflow: hidden;

        > .advert__image-container {
            float: right;
            position: static;
            margin: 0;
        }

        > .advert__text {
            $line-height: 20px;
            $image-height: $line-height * 4 + $gs-baseline;
            $ratio: 5 / 3;
            float: left;
            width: calc(100% - #{$image-height * $ratio});
        }
    }
}

.vergadain--inverse {
    .has-no-flex & {
        > .advert__image-container {
            float: left;
        }

        > .advert__text {
            float: right;
        }
    }
}

.vergadain--manual {
    .advert__image-container {
        height: auto;
    }
}

.vergadain--single {
    @include mq(375px, $until: mobileLandscape) {
        flex-direction: row;

        > .advert__text,
        > .advert__image-container {
            flex: 1;
        }

        > .advert__text {
            margin-left: $gs-gutter / 2;
        }
    }

    .advert__standfirst {
        margin-top: $gs-baseline / 2;
        opacity: .7;
    }

    .advert__image {
        width: 100%;
    }

    &:hover,
    &:focus {
        .button--tertiary {
            @include button-colour(
                transparent,
                null,
                darken($brightness-86, 10%)
            );
        }
    }
}

.advert__title {
    font-size: 1rem;
    line-height: 1.25;

    &.u-text-hyphenate {
        max-width: 100%;
    }
}

.advert__kicker {
    &::after {
        content: '/';
        display: inline-block;
        margin-left: .2em;
        color: mix(#ffffff, $brightness-46, 20%);

    }

    color: $brightness-46;
}

.paidfor-container {
    display: flex;
    align-items: stretch;
}

/* [1] Prevents IE11 bug where the image container's height would go ballooney */
.advert__image-container {
    order: -1;
    margin-bottom: $gs-baseline / 2;
    width: 100%;

    @include mq(tablet) {
        height: 11 * $gs-baseline;
    }

    @include mq(desktop) {
        overflow: auto; /* [1] */
    }

    .has-no-flex & {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
    }
}

.advert__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.advert__meta {
    @include f-textSans;
    color: $brightness-46;
}

.advert__meta--scarcity {
    color: $news-bright;
}

.advert__more {
    margin-top: $gs-baseline / 2;
}

.vergadain--small > .advert__title {
    padding-top: $gs-baseline / 2;
}

@include mq(tablet) {
    .vergadain--large {
        flex-direction: row;
        align-items: stretch;
        padding-bottom: 0;

        > .advert__text {
            flex: 1;
            padding: $gs-baseline / 2 $gs-gutter / 2 $gs-baseline / 2 0;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > .advert__more {
                margin-top: auto;
            }
        }

        > .advert__image-container {
            flex-basis: calc(50% - #{$gs-gutter / 2});
            flex-shrink: 0;
            height: auto;
            order: 0;
            margin-bottom: 0;
            margin-left: $gs-gutter / 2;
        }

        .advert__image {
            width: 100%;
        }
    }

    .vergadain--inverse {
        flex-direction: row-reverse;

        .advert__text {
            padding: $gs-baseline / 2 0 $gs-baseline / 2 $gs-gutter / 2;
        }

        > .advert__image-container {
            margin-left: 0;
            margin-right: $gs-gutter / 2;
        }
    }

    .vergadain--thumbnail {
        .advert__image-container {
            $line-height: 20px;
            $image-height: $line-height * 4 + $gs-baseline;
            $ratio: 5 / 3;
            width: $image-height * $ratio;
        }

        .advert__text {
            padding-left: 0;
        }
    }

    .vergadain--large--1x3 > .advert__image-container {
        flex-basis: calc(75% - #{$gs-gutter / 2});
    }

    .vergadain--large--1x2 > .advert__image-container {
        flex-basis: calc(66.67% - #{$gs-gutter / 2});
    }

    .has-no-flex {
        .vergadain--large--1x3 > .advert__image-container {
            width:  calc(75% - #{$gs-gutter / 2});
        }

        .vergadain--large--1x2 > .advert__image-container {
            width:  calc(66.67% - #{$gs-gutter / 2});
        }

        .vergadain--large--1x3 > .advert__text {
            width:  calc(25% - #{$gs-gutter / 2});
        }

        .vergadain--large--1x2 > .advert__text {
            width:  calc(33.33% - #{$gs-gutter / 2});
        }
    }
}
