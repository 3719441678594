$tls-warning-rule: darken($brightness-97, 20%);

.tls-warning {
    background-color: $brightness-97;
    color: $brightness-7;
    border-bottom: 1px solid $tls-warning-rule;

    .gs-container {
        max-width: gs-span(10);
    }
}

.tls-warning__title {
    @include fs-headline(6);
    font-weight: 700;
    padding: $gs-baseline 0;

    .inline-error-exclamation__svg {
        width: 36px;
        height: 36px;
        padding-right: $gs-gutter / 2;
        vertical-align: middle;

        circle {
            fill: $brightness-7;
        }

        path {
            fill: $brightness-97;
        }
    }
}

.tls-warning__text {
    @include fs-bodyCopy(1);
}

.tls-warning__browser-links {
    @include clearfix;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
}

.tls-warning__browser-links__item {
    float: left;
    &:first-child .tls-warning__browser-link {
        &:before {
            border-left: 0;
        }

        .tls-warning__browser-link__logo {
            padding-left: 0;
        }
    }
}

.tls-warning__browser-link {
    color: $brightness-7;
    display: block;
    position: relative;
    font-size: 0;
    padding-right: $gs-gutter * 2;

    &:hover {
        .tls-warning__browser-link__title {
            text-decoration: underline;
        }
    }

    &:before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        display: block;
        content: '';
        border-left: 1px solid $tls-warning-rule;
    }
}

.tls-warning__browser-link__logo,
.tls-warning__browser-link__title {
    display: inline-block;
    padding-left: $gs-gutter / 2;
    padding-bottom: $gs-baseline;
    vertical-align: middle;
}

.tls-warning__browser-link__logo {
    height: 50px;
}

.tls-warning__browser-link__title {
    @include fs-bodyCopy(1);
    max-width: 50px;
}
